import { template as template_cb92ca0299b94d7aa102fd88ac802694 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cb92ca0299b94d7aa102fd88ac802694(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
