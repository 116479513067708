import { template as template_802f56f44a114586924a42e08316620e } from "@ember/template-compiler";
const FKLabel = template_802f56f44a114586924a42e08316620e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
