import { template as template_291483def6fd4c9ea24bdf979dca13e3 } from "@ember/template-compiler";
const WelcomeHeader = template_291483def6fd4c9ea24bdf979dca13e3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
